import { Grid, SvgIcon, Typography, withStyles } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import { graphql } from 'gatsby';
import GoogleMapReact from 'google-map-react';
import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import ImageViewer from 'react-simple-image-viewer';

import { Contact } from '../blocks/Contact/Contact';
import { Footer } from '../blocks/Footer/Footer';
import { LeaderImage } from '../blocks/LeaderImage/LeaderImage';
import { Navigation } from '../blocks/Navigation/Navigation';
import { MaxWidthContainer } from '../components/MaxWidthContainer/MaxWidthContainer';
import { PropertySpecs } from '../components/PropertySpecs/PropertySpecs';
import { PropertyBySlugQuery } from '../types/graphqlTypes';

import { Layout } from './Layout';

interface IPropertyTemplateProps {
  data: PropertyBySlugQuery;
}

const PropertyTemplate: React.FunctionComponent<IPropertyTemplateProps> = ({
  data,
}) => {
  if (!data || !data.markdownRemark || !data.markdownRemark.frontmatter) {
    return <p>no data</p>;
  }

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  const { frontmatter } = data.markdownRemark;
  const { location } = frontmatter;
  const mapData = {
    center: {
      lat: JSON.parse(location).coordinates[0],
      lng: JSON.parse(location).coordinates[1],
    },
    zoom: 11,
  };

  const leaderImage = frontmatter?.photos?.[0]?.photo
    ? frontmatter.photos[0].photo
    : 'https://via.placeholder.com/150';

  const Photos = [];
  if (frontmatter.photos && frontmatter.photos.length) {
    for (let i = 1; i < frontmatter.photos.length && i < 5; i += 1) {
      Photos.push(
        <SecondaryImage
          item={true}
          xs={5}
          style={{
            backgroundImage: `url(${frontmatter.photos[i].photo})`,
            backgroundSize: 'cover',
            maxWidth: '49%',
            flexBasis: '49%',
            // width: '90%',
          }}
          onClick={() => openImageViewer(i)}
        />,
      );
    }
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'nl',
        }}
      >
        <title>{frontmatter.title}</title>
        <meta name='description' content={frontmatter.description ?? ''} />
      </Helmet>
      <Layout>
        <Navigation />
        <LeaderImage
          section={{
            componentId: {
              frontmatter: {
                buttons: [{ link: '#', buttonText: 'contact' }],
                leaderImage,
              },
            },
          }}
        />
        <MaxWidthContainer container={true} justify='center'>
          <Grid item={true} container={true} xs={10} sm={12}>
            <PropertySpecs
              street={frontmatter.street || 'n/a'}
              houseNumber={frontmatter.houseNumber}
              city={frontmatter.city}
              sqm={frontmatter.sqm}
              rooms={frontmatter.rooms}
              price={frontmatter.price}
              large={true}
            />
            <Grid
              item={true}
              xs={12}
              style={{ borderBottom: '2px solid #B2B4BB', padding: '10px 0' }}
            >
              <Typography variant='h3' style={{ marginTop: '67px' }}>
                Omschrijving
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography>{frontmatter.description}</Typography>
            </Grid>
            <Grid
              container={true}
              item={true}
              xs={12}
              justify='space-between'
              style={{ marginTop: '67px' }}
            >
              <MainImage
                item={true}
                xs={12}
                sm={6}
                style={{
                  backgroundImage: `url(${leaderImage})`,
                }}
                onClick={() => openImageViewer(0)}
              />
              <Grid
                item={true}
                container={true}
                xs={12}
                sm={6}
                wrap='wrap'
                justify='space-between'
                alignContent='space-between'
              >
                {Photos}
              </Grid>
            </Grid>
            <Grid
              item={true}
              xs={12}
              style={{
                borderBottom: '2px solid #B2B4BB',
                padding: '10px 0',
                marginTop: '67px',
              }}
            >
              <Typography variant='h3'>Kenmerken</Typography>
            </Grid>
            {frontmatter.characteristics &&
              frontmatter.characteristics.length &&
              frontmatter.characteristics.map((characteristic, index) => (
                <>
                  <Grid item={true} key={index} xs={12}>
                    <Typography variant='h4'>
                      {characteristic.sectionTitle}
                    </Typography>
                  </Grid>
                  <Grid xs={12} item={true} container={true}>
                    {characteristic.values.map((value, index) => (
                      <>
                        <Grid item={true} xs={3}>
                          {value.key}
                        </Grid>
                        <Grid item={true} xs={9}>
                          {value.value}
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </>
              ))}
            <Grid
              item={true}
              container={true}
              xs={12}
              style={{ marginBottom: '120px', marginTop: '67px' }}
            >
              <Typography variant='h3'>Kaart</Typography>
              <MapContainer item={true} xs={12}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: 'AIzaSyDw2OHtF43F0KWv1uA3MurG8VExId8JGyQ',
                  }}
                  defaultCenter={mapData.center}
                  defaultZoom={mapData.zoom}
                >
                  <SvgIcon component={RoomIcon} />
                </GoogleMapReact>
              </MapContainer>
            </Grid>
          </Grid>
        </MaxWidthContainer>
        <Contact
          section={{
            componentId: {
              frontmatter: {
                title: 'Ja, dit is mijn droomhuis!',
                text: 'Ben jij ervan overtuigd dat het bovenstaande huis jouw droomhuis is? Dan komen we graag met jou in contact. Vul onderstaand formulier in en we nemen zo snel mogelijk contact met je op.',
                image: leaderImage,
              },
            },
          }}
        />
        <Footer section={data.footer.frontmatter} />
      </Layout>
      {isViewerOpen && (
        <ImageViewer
          backgroundStyle={{ zIndex: 100 }}
          src={frontmatter.photos.map(photo => photo.photo)}
          currentIndex={currentImage}
          disableScroll={true}
          onClose={closeImageViewer}
        />
      )}
    </>
  );
};

export default PropertyTemplate;

export const propertyQuery = graphql`
  query PropertyBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        street
        houseNumber
        city
        location
        price
        sqm
        rooms
        description
        characteristics {
          sectionTitle
          values {
            key
            value
          }
        }
        photos {
          photo
          description
        }
      }
    }
    footer: markdownRemark(frontmatter: { type: { eq: "footer" } }) {
      frontmatter {
        name
        phone
        email
        postcode
        city
        street
        links {
          title
          url
        }
      }
    }
  }
`;

const MapContainer = withStyles(theme => ({
  root: {
    height: '600px',
    [theme.breakpoints.down('xs')]: {
      height: '200px',
    },
  },
}))(Grid);

const MainImage = withStyles(theme => ({
  root: {
    height: '450px',
    backgroundSize: 'cover',
    [theme.breakpoints.down('xs')]: {
      height: '200px',
      marginBottom: '5px',
    },
  },
}))(Grid);

const SecondaryImage = withStyles(theme => ({
  root: {
    height: '220px',
    backgroundSize: 'cover',
    [theme.breakpoints.down('xs')]: {
      height: '95px',
      marginBottom: '5px',
    },
  },
}))(Grid);
